import Carousel from 'react-bootstrap/Carousel'
export default function Gallery() {
  return (
    <div id="galleryAreaSection">
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img/gallery/1.webp?text=First slide&bg=373940"
          alt=""
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img/gallery/2.webp?text=Second slide&bg=282c34"
          alt=""
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img/gallery/3.webp?text=Third slide&bg=20232a"
          alt=""
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img/gallery/4.webp?text=First slide&bg=373940"
          alt=""
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img/gallery/5.webp?text=First slide&bg=373940"
          alt=""
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img/gallery/6.webp?text=First slide&bg=373940"
          alt=""
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img/gallery/7.webp?text=First slide&bg=373940"
          alt=""
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img/gallery/8.webp?text=First slide&bg=373940"
          alt=""
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img/gallery/9.webp?text=First slide&bg=373940"
          alt=""
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img/gallery/10.webp?text=First slide&bg=373940"
          alt=""
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img/gallery/11.webp?text=First slide&bg=373940"
          alt=""
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img/gallery/12.webp?text=First slide&bg=373940"
          alt=""
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img/gallery/13.webp?text=First slide&bg=373940"
          alt=""
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img/gallery/14.webp?text=First slide&bg=373940"
          alt=""
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img/gallery/15.webp?text=First slide&bg=373940"
          alt=""
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="img/gallery/16.webp?text=First slide&bg=373940"
          alt=""
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </div>
  );
}