import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useLocation } from "react-router-dom";
import { auth, logout } from "../config/firebase";
import { useWindowDimensions } from "../hooks/useWindowsDimensions";

const Navigation = ({ handleClick, videos }) => {
  const [user] = useAuthState(auth);
  const location = useLocation();
  const { width, isMobile } = useWindowDimensions();

  return (
    <>
      <div className="wrapper">
        <div className="topbar topbar3">
          <div className="container" style={{ marginLeft: "35px" }}>
            <div className="">
              <div className="top-social pull-left">
                <a
                  href="https://www.facebook.com/jardinesdelalmamaldonado/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="flaticon-social"></i>
                </a>
                <a
                  href="https://www.instagram.com/jardinesdelalma/?hl=es"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-instagram"></i>
                </a>

                <a
                  href="https://wa.me/+59895903999"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-whatsapp" aria-hidden="true"></i>
                </a>
              </div>

              <div className="col-md-8 hidden-xs nav-text">
                <p className="hidden-sm">
                  <i className="flaticon-black"></i>info@jardinesdelalma.com
                </p>
                <p className="hidden-sm">
                  <i className="flaticon-technology"></i>4255 9999
                </p>
              </div>
              <div className="logoutContainer pull-right">
                {user && location.pathname === "/dashboard" ? (
                  <span className="logoutLink" onClick={logout}>
                    Logout
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <header className="header3">
        {width >= 768 && (
          <div
            style={{
              backgroundImage: "url(/img/imgportadaynav/logo.png)",
              width: width > 1600 ? "20vw" : "30vw",
              height: "-webkit-fill-available",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        )}
        <div className="container">
          <div className="row navbar-container">
            <div
              className="divLogo"
              style={{ backgroundImage: "url(/img/imgportadaynav/logo.png)" }}
            ></div>
            <div className="col-md-9 col-sm-9 col-xs-4 navbar-1 p-0">
              <div className="cart-header cart-show pull-right"></div>
              <nav
                className={
                  width > 900
                    ? "navbar navbar-default"
                    : "navbar navbar-default pt-0"
                }
              >
                <div className="container">
                  <div
                    id="navbar"
                    className={
                      width <= 1000
                        ? "navbar-collapse collapse"
                        : "d-flex justify-content-center"
                    }
                    aria-expanded="false"
                  >
                    <ul className="nav navbar-nav">
                      <li className="active">
                        <Link to="/">Inicio</Link>
                      </li>
                      <li className="dropdown hide-mobile">
                        <Link
                          to="#servicios"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => handleClick("servicesSection")}
                        >
                          Servicios
                        </Link>
                      </li>
                      <li className="dropdown hide-mobile">
                        <Link
                          to="#galeria"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => handleClick("galleryAreaSection")}
                        >
                          Galería
                        </Link>
                      </li>
                      {videos && videos.length ? (
                        <li className="dropdown hide-mobile">
                          <Link
                            to="#instalaciones"
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                            onClick={() => handleClick("installationsSection")}
                          >
                            Instalaciones
                          </Link>
                        </li>
                      ) : null}

                      <li className="dropdown">
                        <Link
                          to="#novedades"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => handleClick("newsSection")}
                        >
                          Novedades
                        </Link>
                      </li>
                      <li className="dropdown hide-mobile">
                        <Link
                          to="#preguntas-frequentes"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() =>
                            handleClick("frequentQuestionsSection")
                          }
                        >
                          Preguntas Frecuentes
                        </Link>
                      </li>
                      <li className="dropdown dropdown-normal">
                        <Link
                          to="#institutional"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() =>
                            handleClick("institutionalPresentationSection")
                          }
                        >
                          Institucional
                        </Link>
                      </li>
                      <li className="dropdown dropdown-normal">
                        <Link
                          to="#convenios"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => handleClick("agreementsSection")}
                        >
                          Convenios
                        </Link>
                      </li>
                      <li className="dropdown dropdown-normal">
                        <Link
                          to="#servicios-profesionales"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() =>
                            handleClick("provisionalServicesSection")
                          }
                        >
                          Servicios Previsionales
                        </Link>
                      </li>
                      <li className="contacto">
                        <Link
                          to="#contacto"
                          onClick={() => handleClick("contactFormSection")}
                        >
                          Contacto
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#navbar"
                aria-expanded="false"
                aria-controls="navbar"
              >
                Menu
                <span>
                  <em></em>
                  <em></em>
                  <em></em>
                </span>
              </button>
            </div>
          </div>
        </div>
      </header>
      {isMobile && <div style={{ height: "52px" }} />}
      <div className="clearfix"></div>
    </>
  );
};

export default Navigation;
