/* eslint-disable import/no-anonymous-default-export */
/*
export default {
    SERVICE_ID: 'service_nw5myz9',
    USER_ID: 'nBYoEB5Ws0P2aZcMw',
    WORKWITHUS_TEMPLATE_ID: 'template_h4jwi7v',
    CONTACTFORM_TEMPLATE_ID: 'template_ux8yzer'
}
*/

export default {
    SERVICE_ID: process.env.REACT_APP_EMAILJS_SERVICE_ID,
    USER_ID: process.env.REACT_APP_EMAILJS_USER_ID,
    WORKWITHUS_TEMPLATE_ID: process.env.REACT_APP_EMAILJS_WORKWITHUS_TEMPLATE_ID,
    CONTACTFORM_TEMPLATE_ID: process.env.REACT_APP_EMAILJS_CONTACTFORM_TEMPLATE_ID
}







