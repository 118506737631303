import AgreementItem from "./AgreementItem";

export default function AgreementList({ agreements, handleDeleteAgreement, handleEditAgreement }) {
  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th scope="col">Titulo</th>
          <th scope="col">Link</th>
          <th scope="col">Posicion</th>
          <th scope="col">Status</th>
          <th scope="col">Acciones</th>
        </tr>
      </thead>
      <tbody>
        {agreements.map((agreement) => (
          <AgreementItem
            key={agreement.id}
            agreement={agreement}
            editAgreement={() => handleEditAgreement(agreement.id) }
            deleteAgreement={() => handleDeleteAgreement(agreement.id)}
          />
        ))}
      </tbody>
    </table>
  );
}
