/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import Loader from "../../common/Loader";
import { db, storage } from '../../../config/firebase'
import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage"
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs } from "firebase/firestore";
import { useEffect } from "react";
import "./index.css";
import swal from "sweetalert";

export default function MainImage() {
    
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);

    const loadImage = async () => {
        await getDocs(collection(db, "mainImage")).then((querySnapshot) => {
          const image = querySnapshot.docs.map(async (doc) => {
            const img = doc.data();
            const path = img.url;
            const storageRef = ref(storage, path);
            const url = await getDownloadURL(storageRef);
            img.link = url;
            setImage({...img, id:doc.id});
          });
        });
      };
    
      useEffect(() => {
        loadImage();
      }, []);

    const handleChange = (event) => {
        setImage(event.target.files[0]);
    }

    const saveImage = async () => {
        try {
            const storageRef = ref(storage, `/mainImage/${image.name}`);
            const uploadTask = uploadBytesResumable(storageRef, image);

            const dbRef = collection(db, "mainImage");

            const docRef = await addDoc(dbRef, {url:`/mainImage/${image.name}`});
            console.log(docRef)
            console.log(docRef.id)
            const storageMainImageRef = ref(storage, 'mainImage/' + image.name);
            const url = await getDownloadURL(storageMainImageRef);
            console.log(url)

            setImage({link:url});
            swal("Bien hecho!", "Imagen subida exitosamente", "success")
        } catch (e) {
          console.error("Error adding document: ", e);
        }
      };

    const deleteImage = async() => {
        if (image) {
            const docRef = doc(db, "mainImage", image.id);
            deleteDoc(docRef)
            .then(() => {
                setImage(null);
                swal("Bien hecho!", "Imagen borrada exitosamente", "success");
            })
            .catch(error => {
                console.log(error);
            })
        }
        return false;
    }

    return (
        loading ? 
          <Loader />
          :
        <>
        <div className="adminPageContainer">
            <div className="row">
                <div className="form-group">
                    {
                        image && image.link !== undefined ?
                            <img height="200" src={image.link}  />
                        : null
                    }
                    
                    <input
                        type="file"
                        accept="image/*"
                        className="form-control"
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="actions">
                <button onClick={saveImage} className="btn btn-primary">
                    Guardar
                </button>
                <button onClick={deleteImage} className="btn btn-primary">
                    Eliminar
                </button>
            </div>
        </div>
            
        </>
    )
}