import { useEffect, useState } from "react";
import Loader from "../../common/Loader";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../config/firebase";
import AgreementForm from "./AgreementForm";
import AgreementList from "./AgreementList";
import "./index.css";

const Agreements = () => {
  const [agreements, setAgreements] = useState(null);
  const [currentAgreement, setCurrentAgreement] = useState(null);
  const [action, setAction] = useState("Agregar");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) navigate("/admin");
  }, [user]);

  const fetchPost = async () => {
    await getDocs(collection(db, "agreements")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setAgreements(newData);
    });
  };

  useEffect(() => {
    fetchPost();
  }, []);

  const saveAgreement = async (linkObject) => {
    try {
      

      if (linkObject.id !== undefined) {
        const docRef = doc(db, "agreements", linkObject.id);

        updateDoc(docRef, linkObject)
        .then(docRef => {
          fetchPost()
        })
        .catch(error => {
            console.log(error);
        })

      }else {
        const dbRef = collection(db, "agreements");
        const docRef = await addDoc(dbRef, linkObject);
        setAgreements((current) => [...current, { id: docRef.id, ...linkObject }]);
      }
      
      
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const handleDeleteAgreement = async (id) => {
    try {
      await deleteDoc(doc(db, "agreements", id));
      setAgreements(agreements.filter((agreement) => agreement.id !== id));
    } catch {}
  };

  const handleEditAgreement = async (id) => {
    try {
      let ag = agreements.find(agreement=>agreement.id === id)
      console.log('editando ', ag)
      setCurrentAgreement(ag)
      setAction('Guardar')
    } catch {}
  };

  return (
    
      <div className="adminPageContainer">
        <h1>Ingrese nuevo convenio</h1>
        <AgreementForm saveAgreement={saveAgreement} action={action} currentAgreement={currentAgreement} />

        {!agreements ? (
          <Loader />
        ) : !agreements.length ? (
          <div className="alert alert-secondary margin-top-100" role="alert">
            No hay convenios ingresados.
          </div>
        ) : (
          <AgreementList agreements={agreements} handleDeleteAgreement={handleDeleteAgreement} handleEditAgreement={handleEditAgreement}/>
        )}
      </div>
    
  );
};

export default Agreements;
