export default function ProvisionalServices() {
  return (
    <>
      <div id="provisionalServicesSection" className="col-md-12 col-sm-12 panel-body text-center padding-30 colorProv">
        <h3 className="faq-title">Servicios Previsionales</h3>
        <div className="margin-bottom-30"></div>
        <p>
          Servicios Previsionales surge pensando en resolver una necesidad
          individual y familiar. Mediante cuotas mensuales y accesibles, usted y
          su familia contarán con la tranquilidad de estar cubiertos a través de
          alguno de nuestros planes. Somos una empresa dedicada a brindar
          servicios de calidad a partir de una cobertura integral para cada
          situación ofreciendo respeto, atención personalizada, calidez humana y
          soluciones confiables y eficientes. De esta manera la familia evitara
          incurrir en gastos repentinos generados por un desafortunado episodio.
        </p>
      </div>
    </>
  );
}
