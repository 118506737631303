import { useState } from "react";

export default function AgreementItem({ agreement, deleteAgreement, editAgreement }) {
  const [deleting, setDeleting] = useState(false);

  const handleDeleteAgreement = () => {
    setDeleting(true);
    deleteAgreement();
  };

  return (
    <tr>
      <td>{agreement.title}</td>
      <td>{agreement.link}</td>
      <td>{agreement.position}</td>
      <td>{agreement.active ? 'Activo' : 'Inactivo'}</td>
      <td className="agreementActions">
        <a onClick={editAgreement} className="editAgreement">Editar</a>|
        <a onClick={handleDeleteAgreement} className="removeAgreement">
          {deleting ? "eliminando..." : "eliminar"}
        </a>
      </td>
    </tr>
  );
}
