import { useState } from "react";

export default function NewsItem({info}) {
    
    const [displayAll, setDisplayAll] = useState(false);

    const handleDisplay = () => {
        setDisplayAll(!displayAll)
    }

    return <article key={info.id} className="margin-bottom-50 wow fadeInUp" style={{visibility: "visible", animationName: "fadeInUp"}}>
    <div className="post-thumb">
        <img src={info.imageLink} className="img-responsive" alt={info.title} />
    </div>
    <h3 style={{color: "#d2cecb"}}>{info.title}</h3>
    <div className="post-meta">
        <span style={{color:"white"}}><i className="fa fa-calendar" style={{color: "white"}}></i> { info.created_at }</span>
    </div>
    <hr />
    {
        displayAll ? 
            info.description.map((paragraph,index) => <p key={index}>{paragraph}</p>)
        :
        <p>{info.description[0].substring(0, 250)}...</p>
    }
    
    <button className="btn btn-primary-white btn-md padding-horizontal-30" onClick={handleDisplay}>{displayAll ? 'Cerrar' : 'Expandir'}</button>
</article>
}