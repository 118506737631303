import { collection, getDocs } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { useState } from "react";
import { useEffect } from "react";
import { db, storage } from "../config/firebase";
import Loader from "./common/Loader";

export default function MainImage() {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadImage = async () => {
    await getDocs(collection(db, "mainImage")).then((querySnapshot) => {
      querySnapshot.docs.map(async (doc) => {
        const path = doc.data().url;
        const storageRef = ref(storage, path);
        const url = await getDownloadURL(storageRef);
        url.includes(".gif") && !image && setImage(url);
      });
    });
  };

  useEffect(() => {
    loadImage();
    setLoading(false);
  }, []);

  if (loading) return <Loader />;

  return (
    <div id="mainImageSection" className="mainImage">
      <img src={image ?? "./img/mision.webp"} alt="" />
    </div>
  );
}
