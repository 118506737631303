/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import MainImage from "./mainImage/";
import News from "./news/News";
import Videos from "./videos/Videos";
import "./Layout.css";
import { logout } from "../../config/firebase";
import Agreements from "./agreements";
import { Navigate } from "react-router-dom";

export default function Layout() {
    const [section,setSection] = useState('videos')

    const renderSection = () => {
        switch(section) {
            case 'videos':
              return <Videos />;
            case 'convenios':
                return <Agreements />;
            case 'novedades':
                return <News />
            case 'portada':
                return <MainImage />;
            default:
              return null;
        }
    }

    const handleLogout = () => {
      logout()
      Navigate("/admin");
    }

    return <>
            <div className="sidebar">
    <div className="logo-details">
      <i className='bx bxl-c-plus-plus'></i>
      <span className="logo_name">Admin</span>
    </div>
    <ul className="nav-links">
      <li>
        <a onClick={()=>setSection('videos')} href="#" className="active">
          <i className='bx bx-grid-alt'></i>
          <span className="links_name">Videos</span>
        </a>
      </li>
      <li>
        <a onClick={()=>setSection('convenios')} href="#" className="active">
          <i className='bx bx-grid-alt'></i>
          <span className="links_name">Convenios</span>
        </a>
      </li>
      <li>
        <a onClick={()=>setSection('novedades')} href="#">
          <i className='bx bx-box'></i>
          <span className="links_name">Novedades</span>
        </a>
      </li>
      <li>
        <a onClick={()=>setSection('portada')} href="#">
          <i className='bx bx-list-ul'></i>
          <span className="links_name">Imagen Portada</span>
        </a>
      </li>
      <li className="log_out">
        <a onClick={handleLogout} href="#">
          <i className='bx bx-log-out'></i>
          <span className="links_name">Log out</span>
        </a>
      </li>
    </ul>
  </div>
  <section className="home-section">
    <div className="home-content">
      <div className="sales-boxes">
        <div className="recent-sales box">
          <div className="title">{section.toLocaleUpperCase()}</div>
          <div className="sales-details">
            {
               renderSection()
            }
          </div>
        </div>
      </div>
    </div>
  </section>
    </>
}