export default function InstitutionalPresentation() {
  return (
    <div id="institutionalPresentationSection" className="facilities padding-top-50 text-center bg-gray wow fadeInUpBig width-800 coloriP">
      <div className="container">
        <div className="col-md-12 margin-bottom-30 fc-info institucional">
          <div className="section-head text-center">
            <h3 className="h2-pi">Presentación institucional</h3>
          </div>

          <div className="margin-bottom-25"></div>
          <div className="presentational-cards">
          <div className="flip">
              <div className="front" style={{ backgroundImage: "url(img/mision.webp?w=1260&h=750&dpr=2&auto=compress&cs=tinysrgb)" }}>
                <h1 className="text-shadow">MISIÓN</h1>
              </div>
              <div className="back">
                <h2>Misión</h2>
                <p>Ser el Cementerio Parque líder en la región, destacándonos por
                la excelencia y profesionalismo de nuestros servicios y la
                calidez y respeto de nuestro equipo humano.</p>
              </div>
          </div>
          <div className="flip">
              <div className="front" style={{ backgroundImage: "url(img/vision.webp?w=1260&h=750&dpr=2&auto=compress&cs=tinysrgb)" }}>
                <h1 className="text-shadow">VISIÓN</h1>
              </div>
              <div className="back">
                <h2>Visión</h2>
                <p>Ser el principal apoyo de la familia en uno de los momento más
                difíciles de la vida, prestándole un servicio integral que
                satisfaga todas las necesidades que el momento requiera.
                Buscamos servir a la comunidad campoañando desde el respeto.</p>
              </div>
          </div>
          <div className="flip">
              <div className="front" style={{ backgroundImage: "url(img/valores.webp?w=1260&h=750&dpr=2&auto=compress&cs=tinysrgb)" }}>
                <h1 className="text-shadow">VALORES</h1>
              </div>
              <div className="back">
                <h2>Valores</h2>
                <ul className="valores-list">
                  <li>
                    <p className="lead">Homenaje</p>
                    <p className="paragraph">
                      Nuestros servicios pretenden rendir un homenaje digno y
                      acogedor en memoria de nuestros seres queridos.
                    </p>
                  </li>
                  <div className="margin-bottom-30"></div>
                  <li>
                    <p className="lead">Empatía y Respeto</p>
                    <p className="paragraph">
                      Brindar una atención cálida y profesional a través de una
                      fuerte vocación por servir.
                    </p>
                  </li>
                  <div className="margin-bottom-30"></div>
                  <li>
                    <p className="lead">Calidez</p>
                    <p className="paragraph">
                      Acompañar a quienes nos eligen a partir del trabajo
                      oportuno y en equipo, para poder asistir uno de los
                      moemntos más duros.
                    </p>
                  </li>
                </ul>
              </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}
