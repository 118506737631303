import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import apiKey from "../config/emailkeys";

export default function ContactForm() {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    setSuccess(false);
    setError(false);

    emailjs
      .sendForm(
        apiKey.SERVICE_ID,
        apiKey.CONTACTFORM_TEMPLATE_ID,
        "#contactForm",
        apiKey.USER_ID
      )
      .then(
        (result) => {
          setSuccess(true);
          form.current.reset();
        },
        (error) => {
          setError(true);
        }
      );
  };

  return (
    <div
      id="contactFormSection"
      className="contact wow fadeInUpBig"
      style={{ visibility: "visible", animationName: "fadeInUpBig" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-1 hidden-sm"></div>

          <div className="col-md-12">
            <form
              className="contact-form margin-bottom-30 positioned"
              ref={form}
              onSubmit={handleSubmit}
              id="contactForm"
            >
              <div className="text-center">
                <i className="flaticon-write"></i>
                <h3 className="h2">Contacto</h3>
              </div>
              <input
                type="text"
                name="name"
                placeholder="Nombre"
                required="required"
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                required="required"
              />
              <input
                name="phone"
                type="text"
                placeholder="Telefono"
                required="required"
              />
              <textarea rows="4" name="message" placeholder="Mensaje" />
              <div className="text-center">
                <button type="submit" className="btn btn-primary btn-md">
                  Enviar
                </button>
              </div>
            </form>
            {success ? (
              <div id="successMessage" className="successmessage">
                <p>
                  <i className="fa fa-check"></i> Gracias por enviar tu mensaje!
                  Nos pondremos en contacto pronto.
                </p>
              </div>
            ) : null}
            {error ? (
              <div id="failureMessage" className="errormessage">
                <p>
                  <i className="fa fa-close"></i> Hubo un problema al enviar
                  mensaje. Por favor intente de nuevo.
                </p>
              </div>
            ) : null}
          </div>
          <div className="col-md-1 hidden-sm"></div>
        </div>
      </div>
    </div>
  );
}
