import { Link } from "react-router-dom";
import { useWindowDimensions } from "../hooks/useWindowsDimensions";

export default function FrequentQuestions() {
  const { isMobile } = useWindowDimensions();
  return (
    <div
      className="col-md-12 col-sm-12 faq-content faq-tabs about-content padding-bottom-70 wow fadeInUpBig"
      style={{ visibility: "visible", animationName: "fadeInUpBig" }}
    >
      <div className="container">
        <div id="frequentQuestionsSection" className="row">
          <h3 className={isMobile ? "fQ text-center" : "fQ text-center mt-5"}>
            Preguntas Frecuentes
          </h3>
          <div className="col-md-8 m-auto">
            <div className="tab-content">
              <div id="tab1" className="tab-pane fade active in">
                <div
                  className="panel-group accordion accordion2"
                  id="accordion"
                >
                  <div className="panel panel-default">
                    <div className="accordion-heading">
                      <h4 className="accordion-title">
                        <Link
                          className="accordion-toggle collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          to="#collapseOne"
                          aria-expanded="false"
                        >
                          ¿Cuál es el horario de atención de Jardines del Alma?
                          <i className="indicator fa fa-chevron-right"></i>
                        </Link>
                      </h4>
                    </div>
                    <div
                      id="collapseOne"
                      className="panel-collapse collapse"
                      aria-expanded="false"
                      style={{ height: "0px" }}
                    >
                      <div className="panel-body">
                        <p>Las 24 horas del día, todos los días del año.</p>
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="accordion-heading">
                      <h4 className="accordion-title">
                        <Link
                          className="accordion-toggle collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          to="#collapseTwo"
                          aria-expanded="false"
                        >
                          ¿Qué hacer en caso del fallecimiento de una persona?
                          <i className="indicator fa fa-chevron-right"></i>
                        </Link>
                      </h4>
                    </div>
                    <div
                      id="collapseTwo"
                      className="panel-collapse collapse"
                      aria-expanded="false"
                    >
                      <div className="panel-body">
                        <p>
                          Comunicarse con el número de guardia de Jardines del
                          Alma 4255 9999, para que la asesora pueda asistirlo de
                          manera personalizada.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="accordion-heading">
                      <h4 className="accordion-title">
                        <Link
                          className="accordion-toggle collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          to="#collapseThree"
                          aria-expanded="true"
                        >
                          ¿Qué es una Parcela?
                          <i className="indicator fa fa-chevron-down"></i>
                        </Link>
                      </h4>
                    </div>
                    <div
                      id="collapseThree"
                      className="panel-collapse collapse in"
                      aria-expanded="true"
                    >
                      <div className="panel-body">
                        <p>
                          Se trata de un área verde que ubicada en alguno de
                          nuestros distintos sectores del predio de nuestro
                          Cementerio Parque destinado a la inhumación de
                          nuestros seres queridos. Son un bien inembargable,
                          heredable y a perpetuidad.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="accordion-heading">
                      <h4 className="accordion-title">
                        <Link
                          className="accordion-toggle collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          to="#collapseFour"
                          aria-expanded="false"
                        >
                          ¿Qué significa a perpetuidad?
                          <i className="indicator fa fa-chevron-right"></i>
                        </Link>
                      </h4>
                    </div>
                    <div
                      id="collapseFour"
                      className="panel-collapse collapse"
                      aria-expanded="false"
                      style={{ height: "0px" }}
                    >
                      <div className="panel-body">
                        <p>
                          Significa que el derecho de uso funerario sobre la
                          parcela es permanente, perpetuo tanto para el titular
                          de la parcela como para sus sucesores.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="accordion-heading">
                      <h4 className="accordion-title">
                        <Link
                          className="accordion-toggle collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          to="#collapseFive"
                          aria-expanded="false"
                        >
                          ¿Cuál es la capacidad de las parcelas?
                          <i className="indicator fa fa-chevron-right"></i>
                        </Link>
                      </h4>
                    </div>
                    <div
                      id="collapseFive"
                      className="panel-collapse collapse"
                      aria-expanded="false"
                      style={{ height: "0px" }}
                    >
                      <div className="panel-body">
                        <p>
                          Tamaño y capacidad: La superficie de terreno que ocupa
                          una Parcela es de 2.00 mts de largo por 1.00 mts de
                          ancho y 3.5 mts de profundidad.
                        </p>
                        <p>
                          Cada parcela está referenciada por sector, subsector y
                          número, por lo que su ubicación es exacta e indudable.
                          Las parcelas adjudicadas se identifican mediante una
                          placa de granito negro grabada y ubicada a ras del
                          suelo.
                        </p>
                        <p>
                          La capacidad está definida por 3 niveles de
                          profundidad. En cada uno de estos 3 niveles caben 1
                          féretro, o en su defecto 3 urnas de restos o 6 de
                          cenizas
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="accordion-heading">
                      <h4 className="accordion-title">
                        <Link
                          className="accordion-toggle collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          to="#collapseSix"
                          aria-expanded="false"
                        >
                          ¿Cómo se puede adquirir una parcela?
                          <i className="indicator fa fa-chevron-right"></i>
                        </Link>
                      </h4>
                    </div>
                    <div
                      id="collapseSix"
                      className="panel-collapse collapse"
                      aria-expanded="false"
                      style={{ height: "0px" }}
                    >
                      <div className="panel-body">
                        <p>
                          Llamando al 4255 9999. Le asistiremos en todo el
                          proceso para la adquisición de este bien. También
                          puede escribirnos a info@jardinesdelalma.com
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="accordion-heading">
                      <h4 className="accordion-title">
                        <Link
                          className="accordion-toggle collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          to="#collapseSeven"
                          aria-expanded="false"
                        >
                          ¿El parque está abierto a todas las religiones?
                          <i className="indicator fa fa-chevron-right"></i>
                        </Link>
                      </h4>
                    </div>
                    <div
                      id="collapseSeven"
                      className="panel-collapse collapse"
                      aria-expanded="false"
                      style={{ height: "0px" }}
                    >
                      <div className="panel-body">
                        <p>
                          El uso del cementerio no está vinculado a cultos o
                          credos religiosos. Dentro de las instalaciones existe
                          una Capilla adaptable a todas expresiones de fe.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="accordion-heading">
                      <h4 className="accordion-title">
                        <Link
                          className="accordion-toggle collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          to="#collapseEight"
                          aria-expanded="false"
                        >
                          ¿Se puede realizar el velatorio en el parque?
                          <i className="indicator fa fa-chevron-right"></i>
                        </Link>
                      </h4>
                    </div>
                    <div
                      id="collapseEight"
                      className="panel-collapse collapse"
                      aria-expanded="false"
                      style={{ height: "0px" }}
                    >
                      <div className="panel-body">
                        <p>
                          Si. El diseño de la infraestructura del Parque
                          contempla la existencia de sala velatoria en su casco
                          principal, así como cuenta también con una amplia
                          recepción, una capilla y de servicio de cafetería.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="accordion-heading">
                      <h4 className="accordion-title">
                        <Link
                          className="accordion-toggle collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          to="#collapseNine"
                          aria-expanded="false"
                        >
                          ¿Quién puede solicitar la prestación del servicio?
                          <i className="indicator fa fa-chevron-right"></i>
                        </Link>
                      </h4>
                    </div>
                    <div
                      id="collapseNine"
                      className="panel-collapse collapse"
                      aria-expanded="false"
                      style={{ height: "0px" }}
                    >
                      <div className="panel-body">
                        <p>
                          El Titular del contrato es quien dispone la
                          utilización de la Parcela. En caso de múltiples
                          titulares, cualquiera de los co-propiestarios puede
                          realizar la solicitud. En caso de fallecimiento del
                          titular, éste siempre tiene derecho a ser inhumado.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="accordion-heading">
                      <h4 className="accordion-title">
                        <Link
                          className="accordion-toggle collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          to="#collapseTen"
                          aria-expanded="false"
                        >
                          ¿Cómo se contratan los servicios de Jardines del alma?
                          <i className="indicator fa fa-chevron-right"></i>
                        </Link>
                      </h4>
                    </div>
                    <div
                      id="collapseTen"
                      className="panel-collapse collapse"
                      aria-expanded="false"
                      style={{ height: "0px" }}
                    >
                      <div className="panel-body">
                        <p>
                          Comunicarse a través de alguno de nuestros medios de
                          contacto o dirigiéndose a alguna de nuestras oficinas.
                          Por otro lado, también es posible solicitar una
                          entrevista en el domicilio.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="accordion-heading">
                      <h4 className="accordion-title">
                        <Link
                          className="accordion-toggle collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          to="#collapseEleven"
                          aria-expanded="false"
                        >
                          ¿Qué documentación debo presentar ante un
                          fallecimiento?
                          <i className="indicator fa fa-chevron-right"></i>
                        </Link>
                      </h4>
                    </div>
                    <div
                      id="collapseEleven"
                      className="panel-collapse collapse"
                      aria-expanded="false"
                      style={{ height: "0px" }}
                    >
                      <div className="panel-body">
                        <p>
                          Los documentos a presentar son: Cédula de Identidad
                          del fallecido, Credencial Cívica, Recibo de jubilación
                          o sueldo emitido por BPS (en caso que aplique) y
                          cédula Identidad del contratante.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default last">
                    <div className="accordion-heading">
                      <h4 className="accordion-title">
                        <Link
                          className="accordion-toggle collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          to="#collapseTwelve"
                          aria-expanded="false"
                        >
                          ¿Dónde se inscribe el certificado de defunción y cómo?
                          <i className="indicator fa fa-chevron-right"></i>
                        </Link>
                      </h4>
                    </div>
                    <div
                      id="collapseTwelve"
                      className="panel-collapse collapse"
                      aria-expanded="false"
                      style={{ height: "0px" }}
                    >
                      <div className="panel-body">
                        <p>
                          Los funcionarios de Jardines del Alma se encargan de
                          los trámites correspondientes. Este debe ser inscripto
                          en el Registro Civil, presentando el Certificado de
                          Defunción y Cédula de Identidad del Fallecido, así
                          cómo dos testigos, que pueden ser funcionarios de
                          Jardines del Alma.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
