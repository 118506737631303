import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, sendPasswordReset } from "../config/firebase";
import Footer from "./Footer";
import Navigation from "./Navigation";
import "./ResetPassword.css";

function ResetPassword() {
  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/dashboard");
  }, [user, loading]);

  const handleSendResetPassword = async () => {
    await sendPasswordReset(email);
    setSent(true);
  };

  return (
    <>
      <Navigation />
      {sent ? (
        <div className="row resetLinkMessageContainer">
          <div
            className="alert alert-secondary margin-top-100 text-center resetLinkMessage"
            role="alert"
          >
            Link para resetear password enviado, revisa tu email.
          </div>
        </div>
      ) : (
        <div className="reset">
          <div className="reset__container">
            <input
              type="text"
              className="reset__textBox"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail Address"
            />
            <button className="reset__btn" onClick={handleSendResetPassword}>
              Send password reset email
            </button>
          </div>
        </div>
      )}
      ;
      <Footer />
    </>
  );
}
export default ResetPassword;
