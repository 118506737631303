/* eslint-disable default-case */
/* eslint-disable react/jsx-no-undef */
import Agreements from "./Agreements";
import ContactForm from "./ContactForm";
import Footer from "./Footer";
import FrequentQuestions from "./FrequentQuestions";
import Installations from "./Installations";
import InstitutionalPresentation from "./InstitutionalPresentation";
import MainImage from "./MainImage";
import Navigation from "./Navigation";
import News from "./News";
import ProvisionalServices from "./ProvisionalServices";
import WorkWithUs from "./WorkWithUs";
import useVideos from "../hooks/useVideos";
import Gallery from "./Gallery";
import Services from "./Services";

export default function HomePage() {

  const videos = useVideos()

  const handleClick = (section) => {
    let element = "";

    switch (section) {
      case "mainImageSection":
        element = document.getElementById("mainImageSection");
        break;
      case "galleryAreaSection":
        element = document.getElementById("galleryAreaSection");
        break;
      case "installationsSection":
        element = document.getElementById("installationsSection");
        break;
      case "newsSection":
        element = document.getElementById("newsSection");
        break;
      case "institutionalPresentationSection":
        element = document.getElementById("institutionalPresentationSection");
        break;
      case "frequentQuestionsSection":
        element = document.getElementById("frequentQuestionsSection");
        break;
      case "agreementsSection":
        element = document.getElementById("agreementsSection");
        break;
      case "provisionalServicesSection":
        element = document.getElementById("provisionalServicesSection");
        break;
      case "workWithUsSection":
        element = document.getElementById("workWithUsSection");
        break;
      case "contactFormSection":
        element = document.getElementById("contactFormSection");
        break;
      case "servicesSection":
          element = document.getElementById("servicesSection");
          break;
    }

    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Navigation handleClick={handleClick} videos={videos} />
      <MainImage />
      <div className="emptySpace"/>
      <Gallery />
      <InstitutionalPresentation />
      <News showingInstallations={videos.length} />
      <Services />
      <Installations videos={videos}/>
      <FrequentQuestions showingInstallations={videos.length} />
      <Agreements showingInstallations={videos.length} />
      <ProvisionalServices showingInstallations={videos.length} />
      <WorkWithUs showingInstallations={videos.length} />
      <ContactForm showingInstallations={videos.length} />
      <Footer />
    </>
  );
}
