export default function Installations({videos}) {

  return (
    videos.length ?
      <div
        className="col-md-12 col-sm-12 installations"
        id="installationsSection"
      >
        <div className="container">
          <div className="section-head text-center margin-bottom-50 wow fadeInUpBig">
            <h3 className="titleColor">Instalaciones</h3>
            <div className="row videos-container" style={{ display: "flex" }}>
              {videos.map((video) => {
                return (
                  <div
                    key={video.id}
                    className="col-md-4 col-sm-4 resource-item text-center margin-bottom-50 wow fadeInUp"
                  >
                    <h3 className="textTitle">{video.title}</h3>
                    <iframe
                      height={400}
                      width={400}
                      src={video.url}
                      title={video.title}
                    ></iframe>
                    
                    <p className="videoDescription">{video.description}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      :
      null
  );
}
