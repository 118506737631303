import { useState } from "react";

export default function NewsItem({ news, deleteNews, editNews }) {
  const [deleting, setDeleting] = useState(false);

  const handleDeleteNews = () => {
    setDeleting(true);
    deleteNews();
  };
  return (
    <tr>
      <td>{news.title}</td>
      <td>{`${news.description.substring(0, 100)}...`}</td>
      <td>{news.active ? 'Activo' : 'Inactivo'}</td>
      <td className="newsActions">
        <a onClick={editNews} className="editNews">Editar</a>|
        <a onClick={handleDeleteNews} className="removeNews">
          {deleting ? "eliminando..." : "eliminar"}
        </a>
      </td>
    </tr>
  );
}
