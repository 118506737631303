import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import apiKey from "../config/emailkeys";

export default function WorkWithUs() {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email.trim() !== "" && isEmail(email)) {
      emailjs
        .sendForm(
          apiKey.SERVICE_ID,
          apiKey.WORKWITHUS_TEMPLATE_ID,
          "#suscribeForm",
          apiKey.USER_ID
        )
        .then(
          (result) => {
            setSuccess(true);
            //alert("Message Sent, We will get back to you shortly", result.text);
          },
          (error) => {
            alert("An error occurred, Please try again", error.text);
          }
        );
    } else {
      setError(true);
    }
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
    setError(false);
  };

  const isEmail = (emailAddress) => {
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (emailAddress.match(regex)) return true;
    else return false;
  };

  return (
    <div
      id="contactFormSection"
      className="col-md-12 col-sm-12 newsletter-wrap padding-vertical-60"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-8 m-auto">
            <div className="text-center">
              <h3>¿Quiere trabajar con nosotros?</h3>
              <p>Envíe un email a: administracion@jardinesdelalma.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
