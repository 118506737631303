/* eslint-disable no-unreachable */
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../config/firebase";
import Loader from "./common/Loader";

export default function Agreements() {
  const [agreements, setAgreements] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const ags = [];
    const fetchAgreements = async () => {
      const agreements = query(
        collection(db, "agreements"),
        orderBy("position", "asc")
      );
      const querySnapshot = await getDocs(agreements);
      await querySnapshot.forEach((doc) => {
        ags.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      setAgreements(ags);
    };

    fetchAgreements();
    setLoading(false);
  }, []);

  return (
    <div
      id="agreementsSection"
      className="col-md-12 col-sm-12 clients padding-vertical-20 wow fadeInUpBig  bg-gray colorAgree"
      style={{ visibility: "visible", animationName: "fadeInUpBig" }}
    >
      <div className="container text-center margin-bottom-40">
        <div
          className="newsletter-wrap padding-vertical-40 wow fadeInUpBig"
          style={{ visibility: "visible", animationName: "fadeInUpBig" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-8 m-auto">
                <div className="text-center">
                  <h3 className="faq-title">Convenios</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          {loading ? (
            <Loader />
          ) : (
            agreements.map((agreement) => {
              return (
                <div
                  key={agreement.id}
                  className="col-md-2 col-sm-2 col-xs-6 agreement-item"
                >
                  <a
                    href={`https://wa.me/+59895903999?text=Estoy interesado en saber mas sobre este convenio con ${agreement.title}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={agreement.imageLink}
                      className="img-responsive center-block"
                      alt={agreement.title}
                      width="150"
                    />
                  </a>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}
