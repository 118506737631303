import { Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import HomePage from "./components/HomePage";
import ResetPassword from "./components/ResetPassword";
import Layout from "./components/admin/Layout";

function App() {
  return (
    <Routes>
      <Route path="/" exact element={<HomePage />} />
      <Route path="/admin" element={<Login />} />
      <Route path="/reset" element={<ResetPassword />} />
      <Route path="/dashboard" element={<Layout />} />
    </Routes>
  );
}

export default App;
