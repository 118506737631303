import NewsItem from "./NewsItem";
import "./NewsList.css"

export default function NewsList({ news, handleDeleteNews, handleEditNews }) {
  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th scope="col">Titulo</th>
          <th scope="col">Descripcion</th>
          <th scope="col">Status</th>
          <th scope="col">Acciones</th>
        </tr>
      </thead>
      <tbody>
        {news.map((item) => (
          <NewsItem
            key={item.id}
            news={item}
            editNews={() => handleEditNews(item.id)}
            deleteNews={() => handleDeleteNews(item.id)}
          />
        ))}
      </tbody>
    </table>
  );
}
