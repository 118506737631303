import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../config/firebase";
import Loader from "./common/Loader";
import NewsItem from "./NewsItem";

export default function News() {
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const nws = []
      const fetchNews = async () => {
  
        const news = query(collection(db, "news"), orderBy('position', "asc"))
        const querySnapshot = await getDocs(news)
        await querySnapshot.forEach((doc) => {
            nws.push({
            ...doc.data(),
            id: doc.id
          })
        });

        nws.forEach((n)=>n.description = n.description.split('<BREAKLINE>'));
       
        setNews(nws)
      };
  
      fetchNews();
      setLoading(false);
    }, []);

    

    return <div className="col-md-12 col-sm-12" id="newsSection">
        <div className="container">
            <div className="row">
              <div className="col-md-8 m-auto">
                <div className="text-center">
                  <h3 className="newsTitle">Novedades</h3>
                  <div className="row news-container" style={{ display: "flex" }}>
               
            {
                loading ?
                    <Loader />
                :
                    news.map((item, index)=><NewsItem key={index} info={item} />)
            }
            </div>
             </div>
              </div>
            </div>
          </div>
        </div>
}