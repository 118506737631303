import { useEffect, useState } from "react";
import Layout from "../../Layout";
import NewVideoForm from "./NewVideoForm";
import VideoList from "./VideoList";
import Loader from "../../common/Loader";

import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../config/firebase";
import swal from "sweetalert";

const Videos = () => {
  const [currentVideo, setCurrentVideo] = useState(null)
  const [videos, setVideos] = useState(null);
  const [action, setAction] = useState("Agregar");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) navigate("/admin");
  }, [user]);

  const fetchPost = async () => {
    await getDocs(collection(db, "videos")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setVideos(newData);
    });
  };

  useEffect(() => {
    fetchPost();
  }, []);

  const saveVideo = async (linkObject) => {
    try {
      console.log(linkObject);

      if (linkObject.id !== undefined) {
        
        const docRef = doc(db, "videos", linkObject.id);

        updateDoc(docRef, linkObject)
        .then(docRef => {
          fetchPost()
        })
        .catch(error => {
            console.log(error);
        })


      }else {
        
        const dbRef = collection(db, "videos");
        const docRef = await addDoc(dbRef, linkObject);
  
        setVideos((current) => [...current, { id: docRef.id, ...linkObject }]);
      }

      setAction('Agregar')
      
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const handleDeleteVideo = async (id) => {
    try {
      await deleteDoc(doc(db, "videos", id));
      setVideos(videos.filter((video) => video.id !== id));
      swal("Bien hecho!", "Video eliminado exitosamente", "success");
    } catch {}
  };

  const handleEditVideo = async (id) => {
    try {
      let v = videos.find(video=>video.id === id)
      setCurrentVideo(v)
      setAction('Guardar')
    } catch {}
  };
  

  return (
    
      <div className="adminPageContainer">
        <h1>Ingrese nuevo video link</h1>
        <NewVideoForm saveVideo={saveVideo} action={action} video={currentVideo} />

        {!videos ? (
          <Loader />
        ) : !videos.length ? (
          <div className="alert alert-secondary margin-top-100" role="alert">
            No hay videos ingresados.
          </div>
        ) : (
          <VideoList videos={videos} handleDeleteVideo={handleDeleteVideo} handleEditVideo={handleEditVideo} />
        )}
      </div>
    
  );
};

export default Videos;
