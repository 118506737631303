import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../config/firebase";

export default function useVideos() {
    const [videos, setVideos] = useState([]);

    const fetchPost = async () => {
      await getDocs(collection(db, "videos")).then((querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
  
        setVideos(newData);
      });
    };
  
    useEffect(() => {
      fetchPost();
    }, []);

    return videos;
}