import { useEffect, useState } from "react";
import Loader from "../../common/Loader";

import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../config/firebase";
import NewsForm from "./NewsForm";
import NewsList from "./NewsList";
import "./index.css";
import swal from "sweetalert";

const Videos = () => {
  const [news, setNews] = useState(null);
  const [currentNews, setCurrentNews] = useState(null);
  const [action, setAction] = useState("Agregar");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) navigate("/admin");
  }, [user]);

  const fetchPost = async () => {
    await getDocs(collection(db, "news")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setNews(newData);
    });
  };

  useEffect(() => {
    fetchPost();
  }, []);

  const saveNews = async (linkObject) => {
    try {
      if (linkObject.id !== undefined) {
        const docRef = doc(db, "news", linkObject.id);

        updateDoc(docRef, linkObject)
        .then(docRef => {
          swal("Bien hecho!", "Novedad guardada exitosamente", "success");
          fetchPost()
        })
        .catch(error => {
            console.log(error);
        })

      }else {
        const dbRef = collection(db, "news");
        const docRef = await addDoc(dbRef, linkObject);
        setNews((current) => [...current, { id: docRef.id, ...linkObject }]);
        swal("Bien hecho!", "Novedad guardada exitosamente", "success");
      }
      
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const handleDeleteNews = async (id) => {
    console.log(currentNews,id)
    try {
      await deleteDoc(doc(db, "news", id));
      if (currentNews && currentNews.id === id) {
        setCurrentNews(null);
      }
      setNews(news.filter((item) => item.id !== id));
      swal("Bien hecho!", "Novedad eliminada exitosamente", "success");
    } catch {}
  };

  const handleEditNews = async (id) => {
    try {
      let n = news.find(ns=>ns.id === id)
      console.log('editando ', n)
      setCurrentNews(n)
      setAction('Guardar')
    } catch {}
  };


  return (
    
      <div className="adminPageContainer">
        <h1>Ingrese nuevo video link</h1>
        <NewsForm saveNews={saveNews} action={action} currentNews={currentNews} />

        {!news ? (
          <Loader />
        ) : !news.length ? (
          <div className="alert alert-secondary margin-top-100" role="alert">
            No hay novedades ingresadas.
          </div>
        ) : (
          <NewsList news={news} handleDeleteNews={handleDeleteNews} handleEditNews={handleEditNews} />
        )}
      </div>
    
  );
};

export default Videos;
