import VideoItem from "./VideoItem";
import "./VideoList.css"

export default function VideoList({ videos, handleDeleteVideo, handleEditVideo }) {
  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th scope="col">Titulo</th>
          <th scope="col">Link</th>
          <th scope="col">Acciones</th>
        </tr>
      </thead>
      <tbody>
        {videos.map((video) => (
          <VideoItem
            key={video.id}
            video={video}
            editVideo={() => handleEditVideo(video.id)}
            deleteVideo={() => handleDeleteVideo(video.id)}
          />
        ))}
      </tbody>
    </table>
  );
}
