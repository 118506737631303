import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useState, useRef, useEffect } from "react";
import { storage } from "../../../config/firebase";
import Loader from "../../common/Loader";
//import "./AgreementForm.css";
import swal from 'sweetalert';

const AgreementForm = ({ action, saveAgreement, currentAgreement }) => {
  const agreementTitle = useRef();
  const agreementLink = useRef();
  const agreementDescription = useRef();
  const agreementPosition = useRef();
  const agreementStatus = useRef();
  
  const [agreementImage, setAgreementImage] = useState(null)
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);


  useEffect(()=>{
    console.log('useEffect',currentAgreement)
    if (currentAgreement) {
      console.log(currentAgreement)
      agreementTitle.current.value = currentAgreement.title;
      agreementLink.current.value = currentAgreement.link;
      agreementDescription.current.value = currentAgreement.description;
      agreementPosition.current.value = currentAgreement.position;
      agreementStatus.current.value = currentAgreement.active;
      //setAgreementImage(currentAgreement.image);
      setErrors(false)
    }
  },[currentAgreement, action])

  const handleChange = (event) => {
    setAgreementImage(event.target.files[0]);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(false);
    setLoading(true);

    if (
      agreementTitle.current.value.trim() === "" ||
      agreementLink.current.value.trim() === "" ||
      agreementPosition.current.value.trim() === "" ||
      (action === 'Agregar' && !agreementImage)
    ) {
      setErrors(true);
      setLoading(false);
      return;
    }

    try {

      const newAgreement = {
        title: agreementTitle.current.value.trim(),
        description: agreementDescription.current.value.trim(),
        link: agreementLink.current.value.trim(),
        position: parseInt(agreementPosition.current.value.trim()),
        active: agreementStatus.current.value.trim(),
        
      };

      if (currentAgreement) {
        newAgreement.id = currentAgreement.id;
        newAgreement.image = currentAgreement.image??null;
        newAgreement.imageLink = currentAgreement.imageLink??null;
      }

      if (agreementImage)  {

        newAgreement.image = `/files/${agreementImage.name}`

        const storageRef = ref(storage, `/files/${agreementImage.name}`)
        uploadBytesResumable(storageRef, agreementImage).then(()=>{
          getDownloadURL(storageRef).then((url)=>{
            newAgreement.imageLink = url;

            saveAgreement(newAgreement).then(() => {
              reset()
            }).then(()=>swal("Bien hecho!", "Convenio guardado exitosamente", "success"));

          });
        })

      } else {
        saveAgreement(newAgreement).then(() => {
          reset()
        }).then(()=>swal("Bien hecho!", "Convenio guardado exitosamente", "success"));
      }

      
           
    }catch (error) {
      swal("Lo siento!", "Ha ocurrido un error", "error")
      console.log(error)
    }

  };

  const reset = ()=> {
    setLoading(false);
    agreementTitle.current.value = "";
    agreementLink.current.value = "";
    agreementDescription.current.value = "";
    agreementPosition.current.value = "";
    agreementStatus.current.value = false;
    document.getElementById("agreementImage").value = ""
    setAgreementImage(null)
  }


  return (
    <>
      <form className="newFormAgreement card card-body" onSubmit={handleSubmit}>
        <div className="form-group required">
          <label htmlFor="agreementTitle">Titulo*</label>
          <input
            type="text"
            className="form-control"
            id="agreementTitle"
            placeholder="Ingrese titulo del convenio"
            ref={agreementTitle}
          />
        </div>
        <div className="form-group">
          <label htmlFor="agreemenDescription">Descripcion</label>
          <textarea
            className="form-control"
            id="agreemenDescription"
            rows="3"
            ref={agreementDescription}
          ></textarea>
        </div>
        <div className="form-group required">
          <label htmlFor="agreementLink">Link*</label>
          <input
            type="text"
            className="form-control"
            id="agreementLink"
            placeholder="Ingrese enlace al convenio"
            ref={agreementLink}
          />
        </div>
        <div className="form-group">
          <label htmlFor="agreementImage">Imagen*</label>
          <input
            type="file"
            accept="image/*"
            className="form-control"
            id="agreementImage"
            placeholder="Ingrese enlace al convenio"
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="agreementLink">Posicion*</label>
          <input
            type="number"
            min={1}
            className="form-control"
            id="agreementPosition"
            placeholder="Ingrese posicion del convenio"
            ref={agreementPosition}
          />
        </div>
        <div className="form-group">
          <label>Status</label>
          <select ref={agreementStatus} className="form-select" aria-label="Default select example">
            <option value={true} {...agreementStatus?'selected':''}>Activo</option>
            <option value={false} {...agreementStatus? '' :'selected'} >Inactivo</option>
          </select>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <button type="submit" className="btn btn-primary">
            {action}
          </button>
        )}
      </form>
      {errors ? <small>Campos requeridos</small> : null}
    </>
  );
};

export default AgreementForm;
