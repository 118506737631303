/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import "./VideoItem.css"

export default function VideoItem({ video, deleteVideo, editVideo }) {
  const [deleting, setDeleting] = useState(false);

  const handleEditVideo = () => {
    editVideo()
  }
  const handleDeleteVideo = () => {
    setDeleting(true);
    deleteVideo();
  };
  return (
    <tr>
      <td>{video.title}</td>
      <td>{video.url}</td>
      <td>
        <a onClick={handleEditVideo} className="editVideo">Editar</a>|
        <a onClick={handleDeleteVideo} className="removeVideo">
        {deleting ? "Eliminando..." : "Eliminar"}
        </a>
      </td>
    </tr>
  );
}
