export default function Services() {
    return <div className="resources col-md-12 col-sm-12" id="servicesSection">
    <div className="container">
        <h3 className="titleColor">Nuestros Servicios</h3>
        <div className="row">
            <div className="col-md-4 col-sm-4 resource-item text-center margin-bottom-50 wow fadeInUp" data-wow-delay=".1s" style={{visibility: "visible", animationDelay:" 0.1s", animationName: "fadeInUp"}}>
                <div className="ri-thumb">
                    <img src="img/resources/1.jpg" className="img-responsive" alt="" />
                    <h3>Parque</h3>
                </div>
                <p>DIGNIDAD Y RESPETO, PERPETUIDAD y UNION FAMILIAR</p>
                <p>Concebido desde su inicio para este propósito, Jardines del Alma cuenta con amplios y cuidados jardines, Capilla multiculto, Sala de Homenajes...</p>
            </div>
            <div className="col-md-4 col-sm-4 resource-item text-center margin-bottom-50 wow fadeInUp" data-wow-delay=".3s" style={{visibility: "visible", animationDelay:" 0.3s", animationName: "fadeInUp"}}>
                <div className="ri-thumb">
                    <img src="img/resources/2.jpg" className="img-responsive" alt="" />
                    <h3>Cremación</h3>
                </div>
                <p>ARMONIA Y CALIDAD</p>
                <p>Crepúsculo es el primer crematorio del Este del País, y brinda servicios de Cremación en modalidad privada a todo el país...</p>
            </div>
            <div className="col-md-4 col-sm-4 resource-item text-center margin-bottom-50 wow fadeInUp" data-wow-delay=".5s" style={{visibility: "visible", animationDelay:" 0.5s", animationName: "fadeInUp"}}>
                <div className="ri-thumb">
                    <img src="img/resources/3.jpg" className="img-responsive" alt="" />
                    <h3>Servicios Previsionales</h3>
                </div>
                <p>PREVENIR HOY, ES UNA DECISION INTELIGENTE</p>
                <p> Servicios previsionales surge pensando en resolver una necesidad familiar. Mediante cuotas mensuales y accesibles, Usted y su familia...</p>
            </div>
        </div>
    </div>
</div>
}