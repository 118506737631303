import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="wow fadeInUpBig colorFooter p-4">
      <div className="container paddingTop100 paddingBottom40">
        <div className="row">
          <div className="col-md-3 col-sm-6 footer-contact margin-bottom-40">
            <h4 className="h4footer">Contacto</h4>
            <div className="cinfo">
              <p className="abbr">
                <i className="fa fa-map-marker" />
                Ruta 9 Km. 141 – San Carlos
                <br />
                <i className="fa fa-map-marker"></i> Oficina comercial: 3 de
                Febrero 672 esq. Rincón – Maldonado
                <br />
                <i className="flaticon-black"></i> info@jardinesdelalma.com
                <br />
                <i className="flaticon-technology"></i> Teléfono: (+598) 4255
                9999*
                <br />
                <i className="fa fa-mobile"></i> Urgencias: 095 903 999
              </p>
            </div>
            <div className="footer-social">
              <a href="https://www.facebook.com/jardinesdelalmamaldonado/" target="_blank" rel="noreferrer">
                <i className="flaticon-social"></i>
              </a>
              <a href="https://www.instagram.com/jardinesdelalma/?hl=es" target="_blank" rel="noreferrer">
                <i className="fa fa-instagram"></i>
              </a>
              
              <a
                  href="https://wa.me/+59895903999"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-whatsapp" aria-hidden="true"></i>
                </a>
            </div>
          </div>
          <div className="col-md-3 col-md-push-2 col-sm-6 footer-contact margin-bottom-40">
            <h4 className="h4footer">Cementerio</h4>
            <div className="footer-quote">
              <div className="item">
                <iframe
                  loading="lazy"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15771.621159339258!2d-54.89781380470295!3d-34.77393180667832!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x957518bbe8a228a3%3A0x71dd137d72bf9e17!2sJardines+del+Alma!5e1!3m2!1ses-419!2suy!4v1466775719118"
                  width="300"
                  height="350"
                  title="1"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-md-push-3 col-sm-6 footer-contact margin-bottom-40 secondmap">
            <h4 className="h4footer">Oficinas comerciales</h4>
            <div className="footer-quote">
              <div className="item">
                <iframe
                  loading="lazy"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3271.7505486638324!2d-54.95806538474402!3d-34.912709080380644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95751a9ead8e7ac3%3A0x828b970b7f9b9f87!2s3%20de%20Febrero%20672%2C%2020000%20Maldonado%2C%20Departamento%20de%20Maldonado!5e0!3m2!1ses-419!2suy!4v1574866592599!5m2!1ses-419!2suy"
                  width="300"
                  height="350"
                  title="2"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="footer-bottom text-center">
          Copyright &copy; 2022 - Zoo development
        </div>
      </div>
    </footer>
  );
};

export default Footer;
