import { Firestore } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useState, useRef, useEffect } from "react";
import swal from "sweetalert";
import { storage } from "../../../config/firebase";
import Loader from "../../common/Loader";
//import "./NewsForm.css";

const NewsForm = ({ action, saveNews, currentNews }) => {
  const newsTitle = useRef();
  const newsLink = useRef();
  const newsDescription = useRef();
  const newsPosition = useRef();
  const newsStatus = useRef();
  const [image, setImage] = useState(null);
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);


  useEffect(()=>{
    console.log('useEffect',currentNews)
    if (currentNews) {
      newsTitle.current.value = currentNews.title??null;
      newsLink.current.value = currentNews.url??null;
      newsDescription.current.value = currentNews.description??null;
      newsPosition.current.value = currentNews.position??null;
      newsStatus.current.value = currentNews.active??null;
      
      //setImage({name:currentNews.image});
      setErrors(false)
    }
  },[currentNews, action])

  const handleChange = (event) => {
    setImage(event.target.files[0]);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(false);
    setLoading(true);

    if (
      newsTitle.current.value.trim() === "" ||
      newsDescription.current.value.trim() === "" ||
      newsPosition.current.value.trim() === "" ||
      (action === 'Agregar' && !image)
      
    ) {
      setErrors(true);
      setLoading(false);
      return;
    }

    try{

      const newNews = {
        title: newsTitle.current.value.trim(),
        description: newsDescription.current.value.trim().replace(/(\r\n|\r|\n)/g, '<BREAKLINE>'),
        url: newsLink.current.value.trim(),
        position: newsPosition.current.value.trim(),
        active: newsStatus.current.value.trim(),
        created_at: new Date().toISOString().slice(0, 10)
      };
console.log(newNews)
      if (currentNews){
        newNews.id = currentNews.id
        newNews.image = currentNews.image??null;
        newNews.imageLink = currentNews.imageLink??null;
      }
        
      if (image)  {
        newNews.image = `/files/${image.name}`;

        const storageRef = ref(storage, `/files/${image.name}`);
        uploadBytesResumable(storageRef, image).then(()=>{

          getDownloadURL(storageRef).then((url)=>{
            newNews.imageLink = url;
  
            saveNews(newNews).then(() => {
              reset();
              swal("Bien hecho!", "Novedad guardada exitosamente", "success");
            });
  
          });
        })
      }else {
        saveNews(newNews).then(() => {
          reset();
          swal("Bien hecho!", "Novedad guardada exitosamente", "success");
        });
      }

    }catch(error){
      console.log(error)
    }

  };

  const reset = () => {
    setLoading(false);
    newsTitle.current.value = "";
    newsLink.current.value = "";
    newsDescription.current.value = "";
    newsPosition.current.value = "";
    newsStatus.current.value = false;
    document.getElementById("newsImage").value = "";
    setImage(null);
  }

  return (
    <>
      <form className="newFormNews card card-body" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="newsTitle">Titulo*</label>
          <input
            type="text"
            className="form-control"
            id="newsTitle"
            placeholder="Ingrese titulo"
            ref={newsTitle}
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleFormControlTextarea1">Contenido*</label>
          <textarea
            className="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            ref={newsDescription}
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="newsLink">Link</label>
          <input
            type="text"
            className="form-control"
            id="newsLink"
            placeholder="Ingrese enlace externo"
            ref={newsLink}
          />
        </div>

        <div className="form-group">
          <label htmlFor="newsImage">Imagen*</label>
          <input
              id="newsImage"
              type="file"
              accept="image/*"
              className="form-control"
              onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="newsPosition">Posicion*</label>
          <input
            type="number"
            min={0}
            className="form-control"
            id="newsPosition"
            placeholder="Ingrese posicion del convenio"
            ref={newsPosition}
          />
        </div>
        <div className="form-group">
          <label>Status</label>
          <select ref={newsStatus} className="form-select" aria-label="Default select example">
            <option value={true} {...newsStatus?'selected':''}>Activo</option>
            <option value={false} {...newsStatus? '' :'selected'} >Inactivo</option>
          </select>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <button type="submit" className="btn btn-primary">
            {action}
          </button>
        )}
      </form>
      {errors ? <small>Campos requeridos</small> : null}
    </>
  );
};

export default NewsForm;
