import { useEffect } from "react";
import { useState, useRef } from "react";
import swal from "sweetalert";
import Loader from "../../common/Loader";
import "./NewVideoForm.css";

const NewVideoForm = ({ action, saveVideo, video }) => {
  const videoTitle = useRef();
  const videoLink = useRef();
  const videoDescription = useRef();
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);


  useEffect(()=>{
    if (video) {
      videoTitle.current.value = video.title;
      videoLink.current.value = video.url;
      videoDescription.current.value = video.description;
      setErrors(false)
    }
  },[video,action])

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(false);
    setLoading(true);

    if (
      videoTitle.current.value.trim() === "" ||
      videoLink.current.value.trim() === ""
    ) {
      setErrors(true);
      setLoading(false);
      return;
    }
    
    const newVideo = {
      title: videoTitle.current.value.trim(),
      description: videoDescription.current.value.trim(),
      url: videoLink.current.value.trim(),
    };
    if (video)
      newVideo.id = video.id

    saveVideo(newVideo).then(() => {
      setLoading(false);
      videoTitle.current.value = "";
      videoLink.current.value = "";
      videoDescription.current.value = "";
      swal("Bien hecho!", "Video guardado exitosamente", "success");
    });
  };

  return (
    <>
      <form className="newFormVideo card card-body" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="videoTitle">Titulo*</label>
          <input
            type="text"
            className="form-control"
            id="videoTitle"
            placeholder="Ingrese titulo del video"
            ref={videoTitle}
          />
        </div>
        <div className="form-group">
          <label htmlFor="videoDescription">Descripcion del video</label>
          <textarea
            className="form-control"
            id="videoDescription"
            rows="3"
            ref={videoDescription}
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="videoLink">Link*</label>
          <input
            type="text"
            className="form-control"
            id="videoLink"
            placeholder="Ingrese enlace al video"
            ref={videoLink}
          />
        </div>

        {loading ? (
          <Loader />
        ) : (
          <button type="submit" className="btn btn-primary">
            {action}
          </button>
        )}
      </form>
      {errors ? <small>Campos requeridos</small> : null}
    </>
  );
};

export default NewVideoForm;
